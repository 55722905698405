import { FactoryProps } from '@sqior/react/factory';
import { OperationContext } from '@sqior/react/operation';
import { BedAllocationListItemEntity } from '@sqior/viewmodels/occupancy';
import { SelectPatient } from '@sqior/viewmodels/patient';
import { ItemState } from '@sqior/viewmodels/visual';
import { InterweaveExt } from '@sqior/react/uibase';
import { useContext } from 'react';
import styles from './bed-allocation-list-item.module.css';
import { motion } from 'framer-motion';
import { useUIGlobalState } from '@sqior/react/state';
import { useSearchMatch } from '@sqior/react/hooks';

export type BedAllocationListItemProps = FactoryProps<BedAllocationListItemEntity>;

export function BedAllocationListItem(props: BedAllocationListItemProps) {
  const {
    UIGlobalState: { searchQuery },
  } = useUIGlobalState();

  const dispatcher = useContext(OperationContext);

  const selected = useSearchMatch({ searchKeys: props.data.searchKeys, searchQuery });

  const getOpacity = () => {
    if (searchQuery.length === 0) {
      return 1;
    } else if (selected) {
      return 1;
    } else {
      return 0.5;
    }
  };

  return (
    <motion.div
      className={styles['main-container']}
      onClick={() => {
        dispatcher.start(SelectPatient(props.data.select));
      }}
      animate={{
        border: selected ? '1px solid #1CADE4' : '1px solid rgba(0, 0, 0, 0)',
        opacity: getOpacity(),
      }}
      style={{
        borderRadius: 10,
      }}
    >
      <div className={styles['container']}>
        <div>
          <InterweaveExt
            className={
              styles[
                props.data.state === ItemState.Challenged
                  ? 'challenge'
                  : props.data.state === ItemState.Highlighted
                  ? 'highlight'
                  : 'normal'
              ]
            }
            content={props.data.patient}
          />
          {props.data.priority && ' (Priorität)'}
        </div>
        {props.data.targetWard && (
          <div className={styles['highlight']}>{props.data.targetWard}</div>
        )}
        {props.data.diagnosis && <div>{props.data.diagnosis}</div>}
        {props.data.requirements && <div>{props.data.requirements}</div>}
      </div>
    </motion.div>
  );
}

export default BedAllocationListItem;
