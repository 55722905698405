import { ClockTimestamp, ValueObject } from '@sqior/js/data';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { ColorCodes, DashboardStatePath, Icons } from '@sqior/viewmodels/visual';
import { EmergencyClassificationVM } from './orworkflow-overview';
import { CaveSeverity, SpecialtyVM } from '@sqior/viewmodels/patient';
import { DateData } from '@sqior/viewmodels/time';
import { joinPath } from '@sqior/js/url';
import { DailyStartConfig, TransferPriorityGroupVM } from '@sqior/viewmodels/location';
import { ORWorkflowIndicatorVM } from './orworkflow-indicator-vm';
import { SearchKeys } from '@sqior/viewmodels/visual';

export const ORWorkflowDashboardType = 'ORWorkflowDashboard';
export const ORWorkflowOverviewDashboardResponsiveType = 'ORWorkflowOverviewDashboardResponsive';
export const ORWorkflowOverviewORItemType = 'ORWorkflowOverviewORItem';
export const ORWorkflowOverviewProcedureType = 'ORWorkflowOverviewProcedure';
export const ORWorkflowOverviewBlockerType = 'ORWorkflowOverviewBlocker';
export const ORWorkflowOverviewQuotaType = 'ORWorkflowOverviewQuota';
export const ORWorkflowOverviewPreliminaryProcedureType = 'ORWorkflowOverviewPreliminaryProcedure';
export const ORWorkflowOverviewSutureIncisionDurationType =
  'ORWorkflowOverviewSutureIncisionDuration';

/* Data describing the overview dashboard */
export type ORWorkflowOverviewDashboardComponent = EntityHeader & { id: string };
export enum ORWorkflowOverviewDashboardMarkerType {
  Start = 'start',
  End = 'end',
}
export enum ORWorkflowOverviewDashboardMarkerGroup {
  Anesthesia = 'anesthesia',
  Surgery = 'surgery',
}
export type ORWorkflowOverviewDashboardMarker = {
  label: string;
  timestamp: ClockTimestamp;
  type: ORWorkflowOverviewDashboardMarkerType;
  group: ORWorkflowOverviewDashboardMarkerGroup;
};

export type ORWorkflowOverviewDashboardAction = {
  id: string;
  icon: Icons;
  label: string;
  action: OperationSpec;
  disabled?: boolean;
};

export type ORWorkflowOverviewDashboardORItemData = EntityHeader & {
  component: ORWorkflowOverviewDashboardComponent;
  start: ClockTimestamp;
  end: ClockTimestamp;
  extension?: ClockTimestamp;
  endExtension?: ClockTimestamp;
  markers: ORWorkflowOverviewDashboardMarker[];
  opaque: boolean;
  actions: ORWorkflowOverviewDashboardAction[];
  blocked?: boolean;
  blockingReason?: string;
};

export type ProgressData = {
  color?: ColorCodes;
  active: boolean;
};

export type DailyStartHeaderItem = EntityHeader & {
  automatic: boolean; // Automatic yes/no
  header: string; // Status info at top, e.g. Automatische Einschleusung
  title: string; // Main Group Info, e.g. G1
  warnTitle?: boolean; // If true, display title in a warning manner
  shortTitle?: string; // Main Group Info, e.g. Gruppe 1
  timeRange?: string; // Time range for the specifc main group, e.g. 07:15 - 07:20
  detail?: string; // More infos like induction or transfer room , e.g. Schleuse 1, Einleitung 2
  shortDetail?: string; // Short version of more infos
  warnDetail?: boolean; // If true, display title in a warning manner
  progress?: ProgressData[]; // Progress infos
  fadeOut?: boolean; // Display fadeOut yes/no
};

export type ORWorkflowOverviewDashboardORData = EntityHeader & {
  name: string;
  items: ORWorkflowOverviewDashboardORItemData[];
  specialty?: SpecialtyVM;
  dailyStartConfig?: DailyStartConfig;
  location: Entity;
  headerItem?: DailyStartHeaderItem;
  actions: ORWorkflowOverviewDashboardAction[];
  blocked?: boolean; // Flag if the OR is blocked  true/false and undefined if not blocked but available action for blocking
  blockingReason?: string;
};
export type ORWorkflowOverviewDashboardData = EntityHeader & {
  ors: ORWorkflowOverviewDashboardORData[]; // Operating rooms to display
  date: DateData; // Date of the data displayed
  interval: { startHour: number; endHour: number }; // Hours of the OR schedule that shall be displayed (e.g. 0 to 30 means 00:00 to 06:00 of the next day)
  core: { startHour: number; endHour: number }; // Core hours of the OR schedule
  target?: { hours: number; minutes: number }; // Target time to optimize the schedule for
  preliminaryProcedures?: ORWorkflowOverviewDashboardProcedureData[]; // If this is not set, preliminary procedures are not supported, if empty, there are no preliminary procedures present
  stateOverlay?: boolean; // If true, the card overlay state is displayed (anaesthesia or surgery)
};
export enum ORWorkflowOverviewDashboardState {
  Completed = 'completed',
  Running = 'running',
  Planned = 'planned',
}
export enum ORWorkflowOverviewDashboardStage {
  Normal = 'normal',
  Anesthesia = 'anesthesia',
  Surgery = 'surgery',
}

/** Procedure card in dashboard */

export type ORWorkflowOverviewDashboardProcedureData = ORWorkflowOverviewDashboardComponent & {
  name: string; // Patient name
  sex: string; // Patient sex symbol
  dob: string; // Patient date of birth
  age?: string; // Patient age indicator
  description: string; // Procedure description
  state: ORWorkflowOverviewDashboardState; // Procedure state (planned, running, completed)
  duration?: number; // Procedure duration in min
  step: string; // Description of current step
  stage: ORWorkflowOverviewDashboardStage; // Stage of procedure
  indicator?: ORWorkflowIndicatorVM; // Indicator for the procedure
  team?: string[]; // Names of the surgeons
  caveSeverity?: CaveSeverity;
  specialty?: SpecialtyVM; // Specialty of the procedure (e.g. Orthopedics)
  emergencyClassification?: EmergencyClassificationVM;
  prioGroup?: TransferPriorityGroupVM;
  border?: {
    color: string;
    opacity: number;
  };
  select: OperationSpec<ValueObject>;
  shortcutCommand?: {
    text: string;
    select: OperationSpec;
  };
  preSurgicalWard?: string;
  postSurgicalWard?: string;
  postSurgicalCareLevel?: string;
  challenged?: boolean; // Flag if there are challenges with this procedure, so that some kind of warning indicator shall be displayed
} & SearchKeys;
export type ORWorkflowOverviewSutureIncisionDurationData = ORWorkflowOverviewDashboardComponent & {
  duration: number; // Suture to incision of the next procedure duration in min
};

/** Blocker card in dashboard */

export enum ORWorkflowDashboardBlockerType {
  Blocker = 'blocker',
  Comment = 'comment',
}
export type ORWorkflowDashboardBlockerData = ORWorkflowOverviewDashboardComponent & {
  title?: string;
  description?: string;
  type: string;
};

/** Interactive dashboard */

export const ORWorkflowInteractiveDashboardType = 'ORWorkflowInteractiveDashboard';
export type ORWorkflowInteractiveDashboardClusterData = {
  id: string;
  label: string;
};
export type ORWorkflowInteractiveDashboardClusterSelectionData = {
  clusters: ORWorkflowInteractiveDashboardClusterData[];
  selected: number;
};
export type ORWorkflowInteractiveDashboardData = EntityHeader & {
  date: DateData;
  prevDate: boolean;
  nextDate: boolean;
  clusterSelection?: ORWorkflowInteractiveDashboardClusterSelectionData;
  reportURL?: string;
  dashboard?: Entity;
};

export enum ORWorkflowInteractiveDashboardPaths {
  NextDay = 'next',
  PrevDay = 'prev',
  Type = 'type',
  SelectCluster = 'selectCluster',
  ConvertToBlocker = 'convertBlocker',
  ConvertToProcedure = 'convertProcedure',
}
export function NextDay(): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(DashboardStatePath, ORWorkflowInteractiveDashboardPaths.NextDay),
    data: '',
  };
}
export function PrevDay(): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(DashboardStatePath, ORWorkflowInteractiveDashboardPaths.PrevDay),
    data: '',
  };
}
export function SelectORWorkflowOverviewDashboard(type: string): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(DashboardStatePath, ORWorkflowInteractiveDashboardPaths.Type),
    data: type,
  };
}

/** Selects the cluster for display in the interactive OR dashboard */
export function SelectORWorkflowInteractiveDashboardCluster(
  cluster: string
): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(DashboardStatePath, ORWorkflowInteractiveDashboardPaths.SelectCluster),
    data: cluster,
  };
}

/** Converts a procedure into a blocker */
export function ConvertProcedureToBlocker(proc: Entity): OperationSpec<Entity> {
  return {
    type: OperationType.Add,
    path: joinPath(DashboardStatePath, ORWorkflowInteractiveDashboardPaths.ConvertToBlocker),
    data: proc,
  };
}
/** Converts a blocker into a procedure */
export function ConvertBlockerToProcedure(proc: Entity): OperationSpec<Entity> {
  return {
    type: OperationType.Add,
    path: joinPath(DashboardStatePath, ORWorkflowInteractiveDashboardPaths.ConvertToProcedure),
    data: proc,
  };
}

/** Quota card in dashboard */

export type ORWorkflowOverviewDashboardQuota = ORWorkflowOverviewDashboardComponent & {
  title?: string;
  description?: string;
  specialty?: SpecialtyVM;
};
